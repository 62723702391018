import { useEffect, useLayoutEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
import hash from "@emotion/hash"
import { animate, motion, useMotionValue } from "framer-motion"
import getMediaQuery from "css/breakpoints"
import { useFrameAnimation, STEPS_LENGTH, DIAL_SCALE, DIAL_OFFSET_Y } from "./FrameAnimation"
import { mvSubscribe, rafDelay, setProperty } from "./utils"
import { CfgNavigation } from "./navigation"
import { CfgHeader } from "./header"
import { CfgBackgrounds } from "./backgrounds"
import { CfgSteps } from "./steps"
import { CfgRoller } from "./dials"
import { CfgCanvasPanels } from "./canvas"
import { CfgCanvasTabs } from "./canvas"
import { CfgCatalog } from "./catalog"
import { CfgHash } from "./hash"
import { CfgActive } from "./active"
import { CfgLauncherCard } from "./launcher"
import { CfgInstall } from "./install.js"
import { SWAPPING_DELAY } from "./constants.js"
import { useConfigurator } from "components/configurator-v3/context"
import { colors, pageGradientVar } from "./../model/colors"

const Contents = styled.section`
  ${colors}
  position: relative;
  justify-content: end;
  align-items: start;
  box-sizing: border-box;
  width: 100%;
  min-width: 320px;
  height: 100%;
  min-height: 500px;
  overflow: hidden;
  //  background: rgba(255,255,255,1);

  ${getMediaQuery("m")} {
    min-height: 700px;
    //    min-height: 600px;
  }

  &.dark-theme {
    background-color: black;
  }
  &.light-theme {
    background: linear-gradient(to top, #f0f0f0, #fff);
  }
  display: grid;
  grid-template-columns: 1fr;

  &.wv_launched {
    & > section {
      transition: opacity 200ms cubic-bezier(0.61, 1, 0.88, 1) 0ms, visibility 0ms 200ms;
      opacity: 0;
      visibility: hidden;
    }
    /*    & > div {
      transition: opacity 600ms cubic-bezier(0.61, 1, 0.88, 1) 100ms, visibility 0ms 0ms;
      opacity: 1;
      visibility: inherit;
    }*/
  }

  & > section {
    grid-row: 1/-1;
    grid-column: 1/-1;
    position: relative;
    height: 100%;
    z-index: 29;
    background: linear-gradient(to top, #f0f0f0, #fff);
  }

  & > div {
    grid-row: 1/-1;
    grid-column: 1/-1;
    position: relative;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    //    opacity: 0;
    //    visibility: hidden;

    &.cfgv7_frozen {
      //      pointer-events: none;
    }
  }

  @keyframes wvreveal {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  --cfg-dial-offset: ${DIAL_OFFSET_Y.landscape}%;
  --cfg-dial-shrink: ${DIAL_SCALE.landscape}%;
  ${getMediaQuery("m", { max: true })} {
    --cfg-dial-offset: ${DIAL_OFFSET_Y.portrait}%;
    --cfg-dial-shrink: ${DIAL_SCALE.portrait}%;
  }
`

const BgO = styled(motion.div)`
  width: 100%;
  height: 100%;
  background: var(${pageGradientVar});
  z-index: 30;
  pointer-events: none;
`

function BGOverlay() {
  const { bkgfromrv } = useConfigurator()

  const opacity = useMotionValue(bkgfromrv.get() ? 1 : 0)

  function onBg(v) {
    if (!v) animate(opacity, 0, { duration: 0.3 })
  }
  useEffect(() => bkgfromrv.onChange(onBg))

  return <BgO style={{ opacity }} className={`pcs${hash(bkgfromrv.get())}`} />
}

export function CfgContents({ children, Ctx, intro }) {
  console.log("reload CfgContents")
  const { startrmc, step, swapping, orientation, launched, model, settled } = useFrameAnimation(Ctx)
  const rfmain = useRef()
  const rfupper = useRef()
  const rfswapping = useRef(0)
  /*
    function print(v) {
      if (v < 0) return
      //    console.log(">>> print", v)
      const { current: main } = rfmain
      if (!main) return
      Array.from({ length: STEPS_LENGTH + 1 }).map((v, i) => {
        main.classList.remove(`cfgv7_step_${i}`)
      })
      main.classList.add(`cfgv7_step_${v}`)
      if (v === STEPS_LENGTH) main.classList.add(`cfgv7_laststep`)
    }
    useEffect(() => mvSubscribe(step, print), [])
  */
  function onSwapping(v) {
    clearTimeout(rfswapping.current)
    if (!v) return
    rfswapping.current = setTimeout(() => swapping.set(0), SWAPPING_DELAY)
  }
  useLayoutEffect(() => mvSubscribe(swapping, onSwapping), [])
  /*
    function onOrientation(v) {
      if (v < 0) return
      rfupper.current?.classList[v ? "add" : "remove"]("cfg-portrait")
    }
    useLayoutEffect(() => mvSubscribe(orientation, onOrientation, false), [])
  */
  function onLaunched(v) {
    //    if (!v) rfupper.current?.querySelector(".cfg-close").classList.add("wv_reveal")
    rfupper.current?.classList[v ? "add" : "remove"]("wv_launched")
  }
  useLayoutEffect(() => mvSubscribe(launched, onLaunched), [])
  /*
    function onModel(v) {
      if (!v) return
      rfupper.current?.querySelector(".cfg-close").classList.add("wv_reveal")
    }
    useLayoutEffect(() => mvSubscribe(model, onModel), [])
  */
  function onSettled(v) {
    if (v < 1) return
    setTimeout(() => rfupper.current?.classList.remove("cfg-install"), 600)
  }
  useLayoutEffect(() => mvSubscribe(settled, onSettled), [])
  /*
   function onStep(v) {
     if (step <= 0) return
     rafDelay(() => headerize(document.querySelector(".cfgv7Modal")))
   }
   useLayoutEffect(() => mvSubscribe(step, onStep), [])
  
     useLayoutEffect(() => {
       ////        window.addEventListener("resize", onResize, false)
       //        window.addEventListener("orientationchange", onResize, false)
       onResize()
   
       return () => {
         //            window.removeEventListener("resize", onResize, false)
         //           window.removeEventListener("orientationchange", onResize, false)
       }
   
       function onResize(e) {
         setProperty(rfupper.current, "--cfg-height", `${rfupper.current.offsetHeight}px`)
       }
     }, [])
   */
  return (
    <Contents ref={rfupper} className={`cfgv7Contents cfg-install`}>
      <CfgCatalog Ctx={Ctx} />
      <CfgHash Ctx={Ctx} />
      <div ref={rfmain}>
        <CfgBackgrounds Ctx={Ctx} />
        <CfgHeader Ctx={Ctx} />
        <CfgCanvasPanels Ctx={Ctx} />
        <CfgRoller Ctx={Ctx} />
        <CfgSteps Ctx={Ctx} />
        <CfgNavigation Ctx={Ctx} />
        <CfgCanvasTabs Ctx={Ctx} />
        <CfgActive Ctx={Ctx} />
      </div>
      <CfgInstall Ctx={Ctx} />
      <CfgLauncherCard Ctx={Ctx} {...intro} />
      <BGOverlay />
      {children}
    </Contents>
  )
}
