import { useState, useRef, useLayoutEffect } from "react"
import { motion, useMotionValueEvent } from "framer-motion"
import styled from "@emotion/styled"

import { a11yOutline } from "css/a11y"
import getMediaQuery from "css/breakpoints"

import { observeResize, setProperty } from "components/configurator-v3/utils"

import { useRoller } from "./context"

const PageWrapper = styled.div`
  position: relative;
  grid-row: 1/-1;
  grid-column: main;
  width: 100%;
  align-self: end;
  justify-self: center;
  position: relative;
  height: fit-content;
  margin-block-end: 1.75rem;
  display: grid;
  justify-content: center;

  --cfgl-page-ratio: 1;

  ${getMediaQuery("m")} {
    margin-block-end: 2.5rem;
  }
`

const PageContainer = styled.ul`
  position: relative;
  display: flex;
  gap: 12px;
  border: none;
  padding: 0;
  margin: 0;

  transform: scaleX(var(--cfgl-page-ratio));

  @media (prefers-contrast: more) {
    background: #fff;
    padding: 20px 10px;
  }
`

const PageGroup = styled.li`
  display: flex;
  & input.focus-visible + label {
    ${a11yOutline}
  }
`

const PageRadio = styled.input`
  width: 0;
  height: 0;
  appearance: none;
`

const Label = styled(motion.label)`
  position: relative;
  width: 2.5rem;
  height: 0.25rem;
  border-radius: 5px;
  opacity: 0.4;
  border: none;
  padding: 0;
  pointer-events: all;
  text-align: center;
  font-size: clamp(0.875rem, 0.8125rem + 0.1563vw, 1rem); // 14px > 16px
  line-height: 1;
  .light-theme & {
    background: rgb(var(--light-black));
  }
  .dark-theme & {
    background: white;
  }
  transition: background 0.3s;

  & > span {
    position: absolute;
    top: 0;
    font-size: 0.8em;
    white-space: nowrap;
    padding-block: 0 0.7rem;
    transform: translateX(-50%) translateY(-100%) scaleX(calc(1 / var(--cfgl-page-ratio)));
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    pointer-events: none;
  }

  @media (prefers-contrast: more) {
    background: black;
  }

  @media (hover: hover) and (pointer: fine) {
    &.unchecked:hover {
      cursor: pointer;
    }
    &:hover {
      & > span {
        opacity: 1;
      }
    }
  }

  &.unchecked::after {
    content: "";
    position: absolute;
    top: -12px;
    bottom: -12px;
    left: -4px;
    right: -4px;
  }
`

function Page({ index }) {
  const { currentIndex } = useRoller()

  const rfradio = useRef(null)

  const [checked, setChecked] = useState(currentIndex.get() === index)

  function onIndexChange(v) {
    if (v < 0) return
    setChecked(index === v)
  }
  useMotionValueEvent(currentIndex, "change", onIndexChange)

  const variants = {
    checked: { width: "6.25rem", opacity: 1 },
    unchecked: { width: "2.5rem", opacity: 0.4 },
  }

  return (
    <PageGroup>
      <PageRadio
        ref={rfradio}
        type='radio'
        name='cfgf-page-control'
        id={`cfgf-page-control-${index}`}
        value={index}
        checked={checked}
        aria-current={checked}
        readOnly
      />
      <Label
        htmlFor={`cfgf-page-control-${index}`}
        variants={variants}
        initial={checked ? "checked" : "unchecked"}
        animate={checked ? "checked" : "unchecked"}
        whileHover={{ opacity: 1 }}
        className={checked ? "checked" : "unchecked"}
        transition={{ type: "tween", ease: "easeOut", duration: 0.2 }}
      />
    </PageGroup>
  )
}

export default function Pagination(props) {
  const { currentIndex, targetIndex, count } = useRoller()

  const rfmain = useRef(null)
  const rffieldset = useRef(null)

  function onChange(e) {
    targetIndex.set(currentIndex.get(), false)
    const index = Number(e.target.value)
    targetIndex.set(index)
  }

  function onResize(isize) {
    const fsw = rffieldset.current.offsetWidth
    const ratio = Math.min(1, isize / fsw)
    setProperty(rfmain.current, "--cfgl-page-ratio", ratio)
  }
  useLayoutEffect(() => observeResize(rfmain.current, onResize), [])

  return (
    <PageWrapper ref={rfmain}>
      <PageContainer onChange={onChange} ref={rffieldset}>
        {Array.from({ length: count }).map((v, i) => (
          <Page key={`quickviews-page-${i.toString()}`} index={i} />
        ))}
      </PageContainer>
    </PageWrapper>
  )
}
