import { motion } from "framer-motion"
import styled from "@emotion/styled"
import hash from "@emotion/hash"

import { useConsole } from "contexts/Console"

import { Heading as HeadingCN } from "css/classnames"
import { headline70, surtitle70 } from "css/text"
import getMediaQuery from "css/breakpoints"

import TitleSplitter from "./TitleSplitter"

export const KickerCN = `css-${hash("kicker")}`
export const SubtitleCN = `css-${hash("subtitle")}`

const Root = styled(motion.hgroup)`
  display: grid;
  grid-template-rows: repeat(2, min-content);
  height: fit-content;

  & > h2 {
    grid-row-start: 2;
  }

  & > p {
    ${surtitle70}
    grid-row-start: 1;
    margin-block-end: 10px;
  }
`

const Title = styled(motion.h2)`
  ${headline70}
  padding-bottom: 0.7rem;
  margin-bottom: -0.7rem;

  ${getMediaQuery("m")} {
    padding-bottom: 0.7rem;
    margin-bottom: -0.7rem;
  }

  em {
    color: rgb(var(--rolex-green));
    font-style: normal;
  }
`

const transition = {
  type: "tween",
  duration: 0.3,
  ease: "easeOut",
}

const animA = {
  top: {
    show: { y: "0%", opacity: 1, clipPath: "inset(0% 0 -0.2ex 0)", transition },
    hide: { y: "-100%", opacity: 0, clipPath: "inset(100% 0 0 0)", transition },
  },
  bottom: {
    show: { y: "0%", opacity: 1, clipPath: "inset(0 0 0% 0)", transition },
    hide: { y: "100%", opacity: 0, clipPath: "inset(0 0 100% 0)", transition },
  },
}

export default function Heading({ title, subtitle, kicker, id }) {
  const console = useConsole()

  return title && (kicker || subtitle) ? (
    <Root className={HeadingCN}>
      {kicker && (
        <motion.p className={KickerCN} variants={animA.bottom}>
          {kicker}
        </motion.p>
      )}
      <Title dangerouslySetInnerHTML={{ __html: title }} id={id} variants={animA.top} />
      {subtitle && (
        <motion.p className={KickerCN} variants={animA.bottom}>
          {subtitle}
        </motion.p>
      )}
    </Root>
  ) : title ? (
    <Title className={HeadingCN} dangerouslySetInnerHTML={{ __html: title }} id={id} variants={animA.top} />
  ) : null
}
