import { useEffect, useLayoutEffect, useState } from "react"
import { createPortal } from "react-dom"
import { mvSubscribe } from "./utils"
import { useConfigurator } from "./context"
import Configurator from "./index"
import { useLocale } from "contexts/Locale"
import { useDictionary } from "contexts/Dictionary"
import styled from "@emotion/styled"
import getMediaQuery from "css/breakpoints"
import { buttonText, buttonReset, buttonIcon, translucentDark } from "css/buttons"
import { Icon } from "components/icon/Icon"
import { hashIt } from "./hash"
import { SWAP_IN, SWAP_OUT } from "./constants"

const Close = styled.button`
  ${buttonIcon}
  ${translucentDark}
//  --spacing: calc(var(--outer-margin) - var(--height) / 2);

  --spacing: calc(var(--outer-margin) - var(--grid-gap) / 2 - var(--height) / 2);

  ${getMediaQuery("m")} {
//    --spacing: 3.125rem;
    --spacing: calc(var(--outer-margin) / 2 - var(--height) / 2);
  }
  inset-block-start: var(--spacing);
  inset-inline-end: var(--spacing);
  position: absolute;
  z-index: 29;
  animation-name: close-reveal;
  animation-fill-mode: both;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.61, 1, 0.88, 1);
  animation-delay: 0ms;

  @keyframes close-reveal {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

export default function ConfiguratorPlayer({ initialState }) {
  const [active, setActive] = useState(null)
  const { route, fromui, launched, unheaderize } = useConfigurator()
  const locale = useLocale()
  const dictionary = useDictionary()
  console.log("initialState", initialState)

  function kill() {
    //    console.trace("ConfiguratorPlayer kill fromui", fromui.get(), "route", route.get())
    if (fromui.get()) hashIt("")
    setTimeout(() => {
      route.set(null)
      //      console.trace("ConfiguratorPlayer kill route to null")
    }, 10)
    /*      fromui.set(!!from_ui)
          console.log("onRouteonRoute kill", from_ui)
          unheaderize(document.querySelector(".cfgv7Modal")).then(() => {
            route.set(null)
            launched.set(false)
          })*/
  }

  function onClick(e) {
    //    console.log("onRouteonRoute onClick")
    fromui.set(true)
    kill()
  }

  function onRoute(v) {
    //    console.log("ConfiguratorPlayer onRoute", v)
    let [family, startrmc, startstep] = (v || "").split("/").filter(v => !!v?.length)
    //    console.log("ConfiguratorPlayer onRoute split family, startrmc, startstep", family, startrmc, startstep)
    const hash = window.location.hash.split("").slice(1).join("")
    //    console.log("ConfiguratorPlayer onRoute hash", hash)
    if (hash.length) {
      const [cfg, ...all] = hash.split("/")
      if (cfg === "cfg") {
        //        console.log("ConfiguratorPlayer onRoute hash length & valid")
        launched.set(true)
        if (!v) return route.set(all.join("/"))
        else v = all.join("/")
      }
    }
    /*    else if (v && startrmc) {
          route.set(null)
          return
          merci beaucoup ! je crois que je suis épuisé en fait, par pleins de choses
          je vais essayer de me requinquer et de profiter des potes et du bon air volcanique

        }*/
    //    console.log("ConfiguratorPlayer onRoute after hash", v)
    if (!v) {
      //      console.log("ConfiguratorPlayer onRoute no route", v)
      launched.set(false)
      fromui.set(false)
      /*            if (fromui.get())
                    y a un truc qui m'épuise jamais
                    hashIt("")
                  launched.set(false)
                  setActive(null)*/
      return setActive(null)
    }
    ;[family, startrmc, startstep] = v.split("/").filter(v => !!v?.length)
    const istate = initialState[locale.current.codes.www]
    const families = istate?.configurator?.[0]?.families
    const f = families?.find(f => f.family_code === family)
    const intro = f?.intro?.[0]
    const color_set = f?.params?.[0]?.color_set
    //    console.log("fff", intro)
    startrmc = startrmc || f?.params?.[0]?.rmc
    //    console.log("startrmc", startrmc)

    //    const bkg = window?.location?.search?.includes("bkg")
    const cfgprops = {
      family: family,
      startrmc: startrmc,
      startstep: startstep,
      bkgmode: true,
      fromui: fromui,
      launched: launched,
      route: route,
      clear: kill,
      onClose: onClick,
      intro: intro,
      color_set: color_set ? `page-color-set-rolex-v7-color-${color_set}` : null,
      className: startstep ? "wv_straight" : null,
    }

    setActive(
      <Configurator {...cfgprops}>
        <Close className='wv_reveal dark-theme' onClick={onClick} aria-label={dictionary.popinClose()}>
          <Icon type='close' />
        </Close>
      </Configurator>
    )
  }
  useLayoutEffect(() => mvSubscribe(route, onRoute), [])
  /*
    function onHashChange(e) {
      if (fromui.get()) return fromui.set(false)
      const hash = window.location.hash.split("").slice(1).join("")
      if (!hash.length) return kill()
      const [cfg, ...all] = hash.split("/")
      if (cfg !== "cfg") return kill()
      launched.set(true)
      route.set(all.join("/"))
    }
  
    useLayoutEffect(() => {
      window.addEventListener("hashchange", onHashChange, false)
      //    onHashChange()
      return () => window.removeEventListener("hashchange", onHashChange)
    }, [])
  */

  useEffect(() => kill, [])

  return process.browser && active ? createPortal(active, document.body) : null
}
