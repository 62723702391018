import { useState } from "react"
import styled from "@emotion/styled"
import hash from "@emotion/hash"

import { useEnv } from "contexts/Env"
import { useDictionary } from "contexts/Dictionary"

import { headline70, surtitle50 } from "css/text"
import getMediaQuery from "css/breakpoints"
import { Heading as HeadingCN } from "css/classnames"
import { grid } from "css/grid"

import { useWishlist } from "contexts/Wishlist"

import Link from "components/link/Link"
import Image from "components/media/ImageCLD"
import { useRoller } from "./context"

export const KickerCN = `css-${hash("kicker")}`

const Li = styled.li`
  background: rgb(var(--light-grey));
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  ${grid}
  grid-template-rows: 100%;
`

const Title = styled.h2`
  ${headline70}
  padding-bottom: 0.7rem;
  margin-bottom: -0.7rem;

  em {
    color: rgb(var(--rolex-green));
    font-style: normal;
  }
`

const List = styled.ul`
  display: grid;
  grid-auto-flow: column;
  grid-column: 1 / -1;
  grid-row: 1;
  align-self: end;
  justify-self: end;
  overflow: hidden;
  margin-block-end: 5vh;

  --wtchw: calc(var(--rvwdth) / 2);
  --wtchwonly: min(44vw, 380px);

  ${getMediaQuery("s")} {
    --wtchw: min(30vw, 240px);
    --wtchwonly: min(50vw, 270px);
  }
  ${getMediaQuery("m")} {
    --wtchw: min(16vw, 240px);
    --wtchwonly: min(26vw, 270px);
  }

  :has(> li:nth-child(1)) {
    justify-self: center;
  }
  :has(> li:nth-child(2)) {
    ${getMediaQuery("s")} {
      --wtchw: min(45vw, 240px);
    }
    ${getMediaQuery("m")} {
      --wtchw: min(16vw, 240px);
      gap: 4rem;
    }
  }
  :has(> li:nth-child(3)) {
    --wtchw: calc(var(--rvwdth) / 2);
    gap: 0;
    ${getMediaQuery("m")} {
      --wtchw: min(14vw, 240px);
    }
  }

  ${getMediaQuery("m")} {
    grid-column: col 6 / col 12;
    justify-self: center;
    align-self: center;
    margin-block-end: 0;
    /* margin-inline-end: var(--grid-col-unit); */
  }
`

const LiWatch = styled.li`
  display: grid;
  justify-items: center;
  width: var(--wtchw);

  &:only-of-type {
    width: var(--wtchwonly);
  }
`

const WatchCont = styled.div`
  width: 110%;
  ${getMediaQuery("m")} {
    width: 150%;
  }
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Hgroup = styled.hgroup`
  display: grid;
  grid-template-rows: repeat(2, min-content);
  z-index: 1;
  height: fit-content;
  color: #212121;
  grid-column: 1 / span 5;
  grid-row: 1;
  text-align: start;
  justify-items: start;
  align-self: start;

  margin-block-start: 2.5rem;
  margin-inline-start: calc(var(--grid-col-unit) / 2);

  ${getMediaQuery("m")} {
    grid-column: 2 / span 4;
    margin-block-start: 0;
    margin-inline-start: 0;
    align-self: center;
  }

  & > a {
    margin-block-start: 2rem;
  }
  & > h2 {
    grid-row-start: 2;
  }

  & > p {
    ${surtitle50}
    grid-row-start: 1;
    margin-block-end: 10px;
  }

  html.prefers-contrast & {
    color: black !important;
    position: relative;

    ::after {
      background: white;
      content: "";
      height: calc(100% + 30px);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% + 20px);
      z-index: -1;
    }
  }
`

function getImage(env, rmc) {
  return {
    alt: "",
    sizes: "50vw, (min-width: 48rem) 35vw",
    sources: [
      {
        public_id: `catalogue/${env.catalogYear}/upright-bba-with-shadow/${rmc}`,
        version: 1,
        height: "1000",
        width: "680",
        policy: "t_v7-grid",
      },
    ],
  }
}

const link = {
  href: "/wishlist",
  icon: "chevron",
  style: "filled translucent-dark",
}

export default function AshlarReturnWishlist({ index }) {
  const env = useEnv()
  const dictionary = useDictionary()
  const { wishlist } = useWishlist()
  const [watches] = useState(wishlist.reverse().slice(0, 3))
  const { track } = useRoller()

  function onClick(e) {
    track({
      event: "Click",
      customEvents: "returningVisitorClicked",
      index: index,
      ctaName: "View wishlist",
    })
  }

  return (
    <Li data-index={index} className='light-theme'>
      <List>
        {watches.map(rmc => (
          <LiWatch key={rmc}>
            <WatchCont>
              <Image {...getImage(env, rmc)} />
            </WatchCont>
          </LiWatch>
        ))}
      </List>
      <Hgroup className={HeadingCN}>
        <p className={KickerCN}>{dictionary.welcomeBack()}</p>
        <Title dangerouslySetInnerHTML={{ __html: dictionary.rvWishlistTitle() }} />
        <Link {...link} onPress={onClick}>
          {dictionary.viewWishlist()}
        </Link>
      </Hgroup>
    </Li>
  )
}
