import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { motion, useMotionValueEvent } from "framer-motion"
import styled from "@emotion/styled"

import { useEnv } from "contexts/Env"
import { useLocale } from "contexts/Locale"
import { useViewport } from "contexts/Viewport"
import { useConsole } from "contexts/Console"

import getMediaQuery from "css/breakpoints"
import { fullGrid } from "css/grid"

import { handleVisitor } from "utils/handleVisitor.js"

import { colors } from "components/model/colors"
import { useValue } from "../context"
import AshlarReturnWishlist from "./AshlarReturnWishlist"
import AshlarReturnLastConfigured from "./AshlarReturnLastConfigured"
import AshlarReturnFamily from "./AshlarReturnFamily"
import AshlarReturnEvent from "./AshlarReturnEvent"
import AshlarReturnRetailer from "./AshlarReturnRetailer"
import { RollerProvider, useRoller } from "./context"
import Pagination from "./Pagination"
import Arrows from "./Arrows"

const Li = styled(motion.li)`
  position: relative;
  justify-content: center;
  background-color: white;
  height: 300vh;
  margin-block-start: -100vh;
`

const RollerContnr = styled.div`
  height: 100vh;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${colors}
  ${fullGrid}
`

const List = styled.ul`
  grid-column: main;

  --rvwdth: 90vw;
  ${getMediaQuery("m")} {
    --rvwdth: 88vw;
  }

  scroll-snap-align: start;

  display: grid;
  grid-auto-flow: column;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  grid-auto-columns: 100%;
  grid-template-rows: 80vh;
  gap: 10px;

  ${getMediaQuery("m")} {
    grid-template-rows: 75vh;
  }

  ${getMediaQuery("xxxl")} {
    max-width: 120rem;
    margin: 0 auto;
  }

  & > li {
    scroll-snap-align: start;
  }
`

const Overlay = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  height: 300vh;
  width: 100%;
  z-index: 1;
  grid-column: doc;
  grid-row: top-row / bottom-row;
  background: #212121;
  transform: translate3d(0, 0, 0);
  pointer-events: none;
`

const types = {
  wishlist: AshlarReturnWishlist,
  family: AshlarReturnFamily,
  retailer: AshlarReturnRetailer,
  configurator: AshlarReturnLastConfigured,
  eventcard: AshlarReturnEvent,
}

function Roller({ slides }) {
  const ref = useRef()
  const { slide, targetSlide, track } = useRoller()

  function onTargetChange(t) {
    const firstEl = [...ref.current.children][0]
    const elTarg = [...ref.current.children][t].offsetLeft - firstEl.offsetLeft
    ref.current.scrollTo({
      top: 0,
      left: elTarg,
      behavior: "smooth",
    })
  }
  useMotionValueEvent(targetSlide, "change", onTargetChange)

  useLayoutEffect(() => {
    const r = ref.current

    const elements = [...r.children]

    function onIntersectionObserved(entries) {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          const curI = +entry.target.getAttribute("data-index")
          slide.set(curI)
        }
      }
    }
    const observer = new IntersectionObserver(onIntersectionObserved, {
      root: r,
      rootMargin: "0% 2% 0% 2%",
      threshold: 1,
    })

    for (const item of elements) observer.observe(item)

    return () => {
      for (const item of elements) observer.unobserve(item)
    }
  })

  useEffect(() => {
    track({
      event: "Impression",
      customEvents: "returningVisitorDisplayed",
      index: slide.get(),
      ctaName: "",
    })
  }, [])

  return (
    <RollerContnr>
      <List ref={ref}>
        {slides.map((slide, i) => {
          const Slide = types[slide.type]
          return <Slide {...slide} key={slide.type} index={i} />
        })}
      </List>
    </RollerContnr>
  )
}

function VisitorAshlar({ slides }) {
  const { isMobile } = useViewport()
  const { updatePageNum, removePage, num, isReturnVisitor } = useValue()

  const [index, setIndex] = useState(null)
  const [isMob, setIsMob] = useState(isMobile.get())

  const ref = useRef()

  useEffect(() => {
    isReturnVisitor.set(true)
    updatePageNum("AshlarReturnVisitor")
    setIndex(Array.from(ref.current.parentNode.children).indexOf(ref.current))
    return () => removePage("AshlarReturnVisitor")
  }, [isReturnVisitor, removePage, updatePageNum])

  function onNumChange() {
    setIndex(Array.from(ref.current.parentNode.children).indexOf(ref.current))
  }
  useMotionValueEvent(num, "change", onNumChange)

  function onIsMobileChange(b) {
    setIsMob(b)
  }
  useMotionValueEvent(isMobile, "change", onIsMobileChange)

  function onFocus(e) {
    ref.current.scrollIntoView({ block: "center" })
  }

  return (
    <RollerProvider slides={slides}>
      <Li ref={ref} style={{ zIndex: index }} id={`ashlar--${index}`} onFocusCapture={onFocus}>
        <Roller slides={slides} />
        {slides.length > 1 ? <Pagination nb={slides.length} /> : null}
        {slides.length > 1 && !isMob ? <Arrows nb={slides.length} /> : null}
        <Overlay initial={{ opacity: 1 }} animate={{ opacity: 0, transition: { delay: 0.5, duration: 0.9 } }} transition={{ type: "tween", duration: 1 }} />
      </Li>
    </RollerProvider>
  )
}

export default function AshlarReturnVisitor({ event_card }) {
  const console = useConsole()

  const locale = useLocale()
  const env = useEnv()

  const [storage, setStorage] = useState([])

  const getretailer = async rswi => {
    const response = await Promise.resolve(
      new URL(new URL(`/app/establishment/light/jsFront?rswi=${rswi}&countryCodeGeofencing=ALL&langCode=${locale.current.codes.www}`, env.retailers))
    )
      .then(url => new Request(url))
      .then(request => fetch(request))
      .catch(err => err)
    const res = await response.json().catch(err => err)
    return { shortAddress: res.shortAddress, nameTranslated: res.nameTranslated }
  }

  async function updateStorage() {
    const rv = localStorage?.getItem("returnVisitor")
    if (rv) {
      let rvparsed = JSON.parse(rv)
      const retailer = rvparsed.find(o => o.type === "retailer")
      const rswi = retailer?.rswi
      const { shortAddress, nameTranslated } = await getretailer(rswi)
      if (retailer) {
        if (shortAddress && nameTranslated) {
          Object.assign(rvparsed, Object.assign(retailer, { shortAddress, nameTranslated }))
        } else {
          rvparsed = rvparsed.filter(o => o.type !== "retailer")
        }
      }
      setStorage(rvparsed)
    }
  }

  useLayoutEffect(() => {
    const isEventRunning = event_card?.heading

    const wl = localStorage?.getItem("rmcWishList")
    const rv = localStorage?.getItem("returnVisitor")

    if (isEventRunning) {
      if (rv) {
        const rvparsed = JSON.parse(rv)
        const eventExists = rvparsed.find(o => o.type === "eventcard")
        if (!eventExists) {
          handleVisitor({ type: "eventcard", ...event_card })
        } else {
          const arr = rvparsed.map(obj => (obj.type === "eventcard" ? { type: "eventcard", ...event_card } : obj))
          localStorage.setItem("returnVisitor", JSON.stringify(arr))
        }
      } else {
        if (wl) {
          const wlarr = JSON.parse(wl)
          if (wlarr.length) {
            const rmcs = wlarr.reduce((acc, x) => acc.concat([x.productID]), [])
            handleVisitor({ type: "wishlist", list: rmcs })
          }
        }
        handleVisitor({ type: "eventcard", ...event_card })
      }
    } else {
      if (rv) {
        const rvparsed = JSON.parse(rv)
        const eventExists = rvparsed.find(o => o.type === "eventcard")
        if (eventExists) {
          const arr = rvparsed.filter(x => x.type !== "eventcard")
          localStorage.setItem("returnVisitor", JSON.stringify(arr))
        }
      } else {
        if (wl) {
          const wlarr = JSON.parse(wl)
          if (wlarr.length) {
            const rmcs = wlarr.reduce((acc, x) => acc.concat([x.productID]), [])
            handleVisitor({ type: "wishlist", list: rmcs })
          }
        }
      }
    }

    updateStorage()
    window.addEventListener("storage", updateStorage)
    return () => window.removeEventListener("storage", updateStorage)
  }, [])

  return storage.length ? <VisitorAshlar slides={storage} /> : null
}
