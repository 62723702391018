import styled from "@emotion/styled"
import hash from "@emotion/hash"

import { useDictionary } from "contexts/Dictionary"

import { headline70, surtitle50, bold, body50 } from "css/text"
import { Heading as HeadingCN } from "css/classnames"
import getMediaQuery from "css/breakpoints"
import { grid } from "css/grid"

import Link from "components/link/Link"
import Image from "components/media/ImageCLD"
import { useRoller } from "./context"

export const KickerCN = `css-${hash("kicker")}`

const Li = styled.li`
  background-color: white;
  height: 100%;
  width: 100%;
  position: relative;
  ${grid}
  grid-template-rows: 100%;
`

const Figure = styled.figure`
  grid-column: 1 / -1;
  grid-row: 1;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Hgroup = styled.hgroup`
  display: grid;
  grid-template-rows: repeat(2, min-content);
  z-index: 1;
  height: fit-content;
  color: white;
  grid-column: 1 / span 5;
  grid-row: 1;
  text-align: start;
  justify-items: start;
  align-self: start;

  margin-block-start: 2.5rem;
  margin-inline-start: calc(var(--grid-col-unit) / 2);

  ${getMediaQuery("m")} {
    grid-column: 2 / span 4;
    margin-block-start: 0;
    margin-inline-start: 0;
    align-self: center;
  }

  & > a {
    margin-block-start: 2.5rem;
  }
  & > h2 {
    grid-row-start: 2;
  }

  & > p {
    ${surtitle50}
    grid-row-start: 1;
    margin-block-end: 10px;
  }

  html.prefers-contrast & {
    color: black !important;
    position: relative;

    ::after {
      background: white;
      content: "";
      height: calc(100% + 30px);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% + 20px);
      z-index: -1;
    }
  }
`

const Title = styled.h2`
  ${headline70}
  padding-bottom: 0.7rem;
  margin-bottom: -0.7rem;

  ${getMediaQuery("m")} {
    padding-bottom: 0.7rem;
    margin-bottom: -0.7rem;
  }

  em {
    color: rgb(var(--rolex-green));
    font-style: normal;
  }
`

const Address = styled.address`
  font-style: normal;
  margin-block-start: 20px;
  ${body50}
  & > p:first-of-type {
    ${bold}
  }
`

const imageProps = num => {
  return {
    alt: "",
    loading: "lazy",
    sizes: "90vw",
    sources: [
      {
        width: "2440",
        height: "1373",
        public_id: `rolexcom/returning-visitor/retailer/boutique-${num}`,
      },
      {
        width: "780",
        height: "1300",
        public_id: `rolexcom/returning-visitor/retailer/boutique-${num}-portrait`,
        metadata: {
          v7_type: ["portrait"],
        },
      },
    ],
  }
}

export default function AshlarReturnRetailer({ index, shortAddress, nameTranslated, randomNum, url }) {
  const dictionary = useDictionary()

  const { track } = useRoller()

  const link = {
    children: dictionary.moreInfos(),
    icon: "chevron",
    style: "filled translucent-dark",
  }

  function onClick(e) {
    track({
      event: "Click",
      customEvents: "returningVisitorClicked",
      index: index,
      ctaName: "More infos",
    })
  }

  return (
    <Li data-index={index} className='dark-theme'>
      <Figure>
        <Image {...imageProps(randomNum)} />
      </Figure>
      <Hgroup className={HeadingCN}>
        <p className={KickerCN}>{dictionary.welcomeBack()}</p>
        <Title id='linkLabel' dangerouslySetInnerHTML={{ __html: dictionary.rvRetailerTitle() }} />
        <Address>
          <p id='retailerName' dangerouslySetInnerHTML={{ __html: nameTranslated }} />
          <p dangerouslySetInnerHTML={{ __html: shortAddress }} />
        </Address>
        <Link {...link} href={url} aria-labelledby='linkLabel' aria-describedby='retailerName' onPress={onClick} />
      </Hgroup>
    </Li>
  )
}
