import { createContext, useContext } from "react"
import { useMotionValue } from "framer-motion"
import useScrollLock from "hooks/useScrollLock"
import { useHeader } from "contexts/Header"
import { useSubNav } from "contexts/SubNav"
import { rafDelay } from "./utils"

export const Context = createContext()

export const ConfiguratorProvider = ({ children }) => {
  const scrollLock = useScrollLock()
  const header = useHeader()
  const subnav = useSubNav()

  const route = useMotionValue(null)
  const launched = useMotionValue(false)
  const fromui = useMotionValue(false)
  const bkgfromrv = useMotionValue("")

  function headerize(el) {
    return new Promise(resolve => {
      const domheader = document.querySelector(".header-nav")
      if (domheader) domheader.classList.add("header-cfg")
      scrollLock.lock(el)
      header.freeze("cfg", 0)
      subnav?.hide?.()
      rafDelay(() => {
        el?.classList.add("headerized")
        rafDelay(resolve)
      })
    })
  }

  function unheaderize(el) {
    return new Promise(resolve => {
      el?.classList.remove("headerized")
      header.unfreeze("cfg", 1)
      subnav?.unhide?.()
      scrollLock.unlock(el)
      rafDelay(() => {
        const domheader = document.querySelector(".header-nav")
        if (domheader) domheader.classList.remove("header-cfg")
        rafDelay(resolve)
      })
    })
  }

  return <Context.Provider value={{ route, launched, fromui, bkgfromrv, headerize, unheaderize }}>{children}</Context.Provider>
}

export const useConfigurator = () => useContext(Context)
