import { useEffect, useContext, useRef } from "react"
import { useMotionValue, useTransform } from "framer-motion"

export function CanvasAnimationProvider({ children, Ctx }) {
    const frames = useRef(new Map())
    const canvasctx = useRef(null)
    const preload = useMotionValue(0)

    function clearFrames() {
        return new Promise(resolve => {
            ;[...frames.current.entries()].map(([k, { src }]) => {
                if (typeof src === "string" && src.indexOf("blob:") === 0) {
                    URL.revokeObjectURL(src)
                    console.log("clearFrames revoked", src)
                }
                frames.current.delete(k)
            })

            resolve()
        })
    }

    async function kill() {
        await clearFrames()
    }
    useEffect(() => kill, [])

    return <Ctx.Provider value={{ frames, canvasctx, preload, clearFrames }}>{children}</Ctx.Provider>
}

export function useCanvasAnimation(ctx) {
    return useContext(ctx)
}
