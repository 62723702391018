import { useEffect, useContext, useLayoutEffect, useRef } from "react"
import { useMotionValue, useTransform } from "framer-motion"
import { mvSubscribe, isTouchEnabled } from "./utils"
import { useLocale } from "contexts/Locale"
import { storeCustomEvent, waTriggerEvent } from "components/analytics/DigitalDataLayer"
import { getPriceDatas } from "components/price/utils"
import { useUser } from "contexts/User"
import { useEnv } from "contexts/Env"

export const STEPS = ["flagship", "model", "size", "material", "bezel", "bracelet", "dial"]
export const STEPS_LENGTH = STEPS.length - 1
export const FEATURES = new Map([["material", "part_material_id"]])
export const DIAL_SCALE = { portrait: 60, portrait_fat: 100, landscape: 65 }
export const DIAL_OFFSET_Y = { portrait: -20, portrait_fat: 0, landscape: -10 }
export const DIAL_BOUNCE = { portrait: 0.9, portrait_fat: 1, landscape: 0.9 }
export const PK20_SHADOW_RATIO = 1380 / 1180
export const PK20_FIT = {
  portrait: {
    x: 0,
    y: -6.8,
    s: 0.74,
    ratio: 780 / 1356,
  },
  landscape: {
    x: 0,
    y: -6.8,
    s: 0.74,
    ratio: 800 / 1380,
  },
}

export function FrameAnimationProvider({ children, Ctx, family, startrmc, startstep, fromui, launched, route, clear, color_set }) {
  const env = useEnv()
  const { countryCode } = useUser()

  const catalog = useRef(null)
  const fetching = useMotionValue(family)
  const model = useMotionValue(null)
  const settled = useMotionValue(2 * !!startstep - 1)
  const step = useMotionValue(0)
  const restore = useMotionValue(0)
  const fromlaststep = useMotionValue(null)
  const swapping = useMotionValue(0)
  const beforelaststep = useMotionValue(0)
  const rolling = useMotionValue(false)
  const loading = useMotionValue(true)
  const preloading = useMotionValue(true)
  const feature = useMotionValue(null)
  const locale = useLocale()
  const orientation = useMotionValue(-1)
  const resized = useMotionValue(null)
  const mobile = useMotionValue(-1)
  const bkg = useMotionValue(!!startstep ? null : color_set)
  const readyPanels = useMotionValue(false)
  const readyTabs = useMotionValue(false)
  const canvasview = useMotionValue("watch")
  const rfcanvasview = useRef("watch")
  const rfnewpanel = useRef(true)
  const dialfilters = useRef(null)
  let waStarted = useRef(true).current
  const touchEnabled = useRef(null)
  const newpanel = useMotionValue(false)

  const ready = useTransform([rolling, loading], ([...args]) => args.every(v => !v))
  const readyCanvas = useTransform([readyPanels, readyTabs], ([...args]) => args.every(v => v))

  const getModel = (rmc = model.get()) => {
    return !!catalog.current && !!rmc ? catalog.current.find(v => v.rmc === rmc) : null
  }

  function lastStep() {
    return step.get() >= STEPS_LENGTH
  }

  const dfilter = useMotionValue(null)

  const modellist = useTransform([step, model], ([step, model]) => {
    if (step < 0 || !model) return null
    const m = getModel(model)
    if (!m) return null
    step = step || m.next[0]
    return m[`variations_${STEPS[step]}`]
  })

  const updateStep = (v = -1) => {
    if (v < 0) {
      const m = getModel()
      if (!m) return
      v = (startstep && STEPS.indexOf(startstep)) || m.next[0]
    }
    step.set(v)
    swapping.set(1)
    wa()
    fromlaststep.set(lastStep())
  }

  function step2wa() {
    let sindex = step.get()
    if (!launched.get()) return "home"
    switch (sindex) {
      case 2:
        return "size"
      default:
        return `${STEPS[sindex].replace("flagship", "home")}`
    }
  }

  function setBeforeLastStep() {
    const m = getModel()
    if (!m) return
    let v = step.get()
    let nstep = m.next[v]
    if (nstep < 0 && v !== STEPS_LENGTH) nstep = STEPS_LENGTH
    beforelaststep.set(+(nstep === STEPS_LENGTH))
  }

  async function wa(data = {}) {
    if (!process.browser) return
    if (data.configuratorCompleted === "true") {
      storeCustomEvent({ eventName: "configuratorCompleted" })
      return
    }
    const m = getModel()
    if (!m) return

    let split = window.location.pathname.slice(1).split("/")
    if (locale.current.codes.www !== locale.defaultLocale.codes.www) split.splice(0, 1)
    let [siteSection, siteSubSection] = split
    siteSubSection = siteSubSection || ""

    const eventName = ["configuratorStepViews"]

    //    if (waStarted && !launched.get()) eventName.push("viewVariationsCTA")
    if (waStarted && launched.get()) eventName.push("configuratorStarted")
    if (step.get() === 6) eventName.push("configuratorLastStep")

    const { price, currency } = await getPriceDatas({ rmc: m.rmc, countryCode, locale, env })

    waTriggerEvent({
      eventName: eventName,
      eventType: "Page Load",
      pageName: `watches:${m.familyCode}:configure:${m.rmc}:step:${step2wa()}`,
      configurator: {
        step: step2wa(),
      },
      products: [
        {
          productCategory: m.familyCode,
          productID: m.rmc,
          productQuantity: "1",
          productModel: m.nameCode,
          productPrice: price,
          currency: currency,
        },
      ],
      ...data,
    })
    waStarted = !launched.get()
  }

  useEffect(() => mvSubscribe(step, setBeforeLastStep, false), [])
  useEffect(() => mvSubscribe(model, setBeforeLastStep, false), [])

  function syncHeight() {
    //    const modal = document.querySelector(".cfgv7Modal")
    const { offsetWidth, offsetHeight } = document.querySelector(".cfgv7Contents")
    //    rfwh.current = { offsetWidth, offsetHeight }
    orientation.set(+(offsetWidth < offsetHeight))
    resized.set({ offsetWidth, offsetHeight })
    mobile.set(+(offsetWidth <= 768))
    //    document.documentElement.style.setProperty("--cfg-window-inner-height", `${window.innerHeight}px`)
  }

  useLayoutEffect(() => {
    if (!process.browser) return
    touchEnabled.current = isTouchEnabled()
    window.addEventListener("resize", syncHeight, true)
    window.addEventListener("orientationchange", syncHeight, true)
    syncHeight()

    return () => {
      window.removeEventListener("resize", syncHeight, true)
      window.removeEventListener("orientationchange", syncHeight, true)
    }
  }, [])

  function kill() {
    waStarted = true
    model.set(null)
    step.set(0)
    swapping.set(0)
    //    clear()
  }

  useEffect(() => kill)

  return (
    <Ctx.Provider
      value={{
        catalog,
        fetching,
        family,
        startrmc,
        model,
        modellist,
        getModel,
        step,
        lastStep,
        restore,
        fromlaststep,
        updateStep,
        swapping,
        wa,
        beforelaststep,
        rolling,
        loading,
        preloading,
        ready,
        feature,
        dfilter,
        orientation,
        mobile,
        resized,
        bkg,
        readyPanels,
        readyTabs,
        readyCanvas,
        canvasview,
        rfcanvasview,
        rfnewpanel,
        fromui,
        launched,
        route,
        dialfilters,
        touchEnabled,
        settled,
        startstep,
        newpanel,
        clear,
      }}
    >
      {children}
    </Ctx.Provider>
  )
}

export function useFrameAnimation(ctx) {
  return useContext(ctx)
}
