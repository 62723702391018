import { useEffect, useRef, useLayoutEffect, useState, memo } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { motion, transform, useTransform, useScroll } from "framer-motion"
import { useFrameAnimation } from "./FrameAnimation"
import { useRollerAnimation } from "./RollerAnimation"
import { mvSubscribe } from "./utils"
import { useMediaLoader, setPicture, setSource } from "./utils"
import getMediaQuery from "css/breakpoints"
import { useEnv } from "contexts/Env"
import { CfgArrows } from "./dials.arrows"
import Image from "components/media/ImageCLD"

const CLD_FEATURES_MAP = new Map([
  ["model", "upright-bba-with-shadow"],
  ["material", "configurator/material"],
  ["bezel", "configurator/bezel-with-shadow"],
  ["bracelet", "bracelet-highlight-on-full-watch"],
  ["dial", "configurator/raw-dial-with-shadow"],
])

const SCROLLxN = new Map([
  [1, [100, 1]],
  [2, [50, .9]],
  [3, [33.3333, 1]],
  [13, [33.3333, .9]],
  [4, [25, 1]],
  [5, [20, .9]],
])

function x(v) {
  return css`
    &:before,
    &:after {
      content: "";
      flex: 0 0 ${(100 - SCROLLxN.get(v)[0] * SCROLLxN.get(v)[1]) / 2}%;
    }
    & li {
      flex: 0 0 ${SCROLLxN.get(v)[0] * SCROLLxN.get(v)[1]}%;
    }
    --wv-step-div: ${v % 10};
    --wv-step-offset: ${SCROLLxN.get(v)[1]};
  `
}

const SWAP_IN = css`
  transition: opacity 400ms cubic-bezier(1, 1, 1, 1) 0ms, visibility 0ms 0ms;
  opacity: 1;
  visibility: inherit;
`

const SWAP_OUT = css`
  opacity: 0;
  visibility: hidden;
  will-change: opacity, visibility;
`

const Main = styled.section`
  --spacing: 3.125rem;

  ///  background: rgba(0, 255, 255, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  @media (hover: hover) and (pointer: fine) {
    left: var(--spacing);
    width: calc(100% - 2 * var(--spacing));
  }
  height: 100%;
  overflow: visible;

  & ul {
    height: 100%;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    scrollbar-width: none;
    scroll-behavior: auto;
    pointer-events: auto;
    user-select: none;

    &::-webkit-scrollbar {
      display: none;
    }

    scroll-snap-type: x mandatory;
    justify-items: center;

    & li {
      scroll-snap-align: center;
      //      height: 100%;
      flex: 0 0 25%;
      position: relative;
      overflow: hidden;
      will-change: opacity;
      cursor: pointer;

      @media (hover: hover) and (pointer: fine) {
        &[aria-current="false"]:hover {
          figcaption {
            opacity: 1;
            transition: opacity 1s cubic-bezier(0.23, 1, 0.32, 1) 0.3s;
          }
        }
      }

      & figure {
        position: relative;
        height: 100%;

        & img {
          pointer-events: none;
          display: block;
          position: absolute;
          height: 100%;
          width: auto;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          object-fit: cover;
          [dir="rtl"] & {
            left: auto;
            right: 50%;
            transform: translateX(50%);
          }
        }
        font-size: clamp(0.875rem, 0.8125rem + 0.1563vw, 1rem); // 14px > 16px
        line-height: 1;

        & figcaption {
          position: absolute;
          transform: translateZ(0);
          left: 0;
          top: 68%;
          width: 100%;
          text-align: center;
          opacity: 0;
          font-size: 0.8em;
        }
      }
    }

    ${x(2)}
    @media (min-width: 1024px) {
      ${x(4)}
    }

    @media (hover: hover) and (pointer: fine) {
      ${x(1)}
    }

    @media (min-width: 640px) and (hover: hover) and (pointer: fine) {
      ${x(3)}
    }

    @media (min-width: 960px) and (hover: hover) and (pointer: fine) {
      ${x(13)}
    }

    @media (min-width: 1280px) and (hover: hover) and (pointer: fine) {
      ${x(5)}
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      > nav {
        visibility: visible;
      }
    }
  }
`

const CfgStepListItem = memo(({ ctx, index, picture, caption }) => {
  //  console.log("item", index)
  const { focus, rmfocus, x, W, N, R } = useRollerAnimation(ctx)
  const rfmain = useRef(null)

  function redirect() {
    const el = document.querySelector(".cfgv7Contents .cfgview")
    if (!el) return
    el.click()
  }

  function onClick(e) {
    if (index === focus.get()) return redirect()
    rmfocus.set(index)
  }

  const ranger = useTransform([W, N, R], ([W, N, R]) => {
    let w = (W * R) / N
    let pos = w * index
    let inc = +(N >= 4)
    console.log("W, N, R", W, N, R)
    return transform(
      [
        pos - (inc + 1.25) * w,
        pos - (inc + 1) * w,
        pos - 0.75 * w,
        pos - 0.25 * w,
        pos,
        pos + 0.25 * w,
        pos + 0.75 * w,
        pos + (inc + 1) * w,
        pos + (inc + 1.25) * w,
      ],
      [1 - (N % 2), 1, 1, 0.25, 0, 0.25, 1, 1, 1 - (N % 2)]
    )
  })

  const opacity = useTransform([x, ranger], ([x, ranger]) => {
    return ranger(x)
  })

  function onFocus(v) {
    rfmain.current?.setAttribute("aria-current", v === index)
  }

  useEffect(() => mvSubscribe(focus, onFocus), [])

  return (
    <motion.li ref={rfmain} onClick={onClick} style={{ opacity }}>
      <figure>
        <Image sources={[picture]} loading='eager' sizes='(min-width: 48rem) 33vw, 50vw' />
        <figcaption dangerouslySetInnerHTML={{ __html: caption }}></figcaption>
      </figure>
    </motion.li>)

  /*
    return (
      <motion.li ref={rfmain} onClick={onClick} style={{ opacity }}>
        
        <figure>
          <img {...picture.sources[0]} alt='' loading='eager' />
          <figcaption dangerouslySetInnerHTML={{ __html: caption }}></figcaption>
        </figure>
      </motion.li>
    )*/
})

export const CfgStepList = memo(({ ctx, rctx, diallist }) => {
  const { lastStep, model, getModel, swapping, rolling, step, dfilter } = useFrameAnimation(ctx)
  const { focus, rmfocus, x, W, N, R, count, readySL, readyALL } = useRollerAnimation(rctx)
  const rfmain = useRef()
  const rflist = useRef()
  const rftm = useRef(null)
  const env = useEnv()
  const rtl = process.browser && document.querySelector("html").getAttribute("dir") === "rtl"
  //  const rfitems = useItems(step, modellist, model, beforelaststep)
  const [items, setItems] = useState(null)
  useMediaLoader(readySL, "CFGSL", rfmain, items, false, 40, 300)

  const splitted = useTransform(diallist, v => v?.split(","))

  function onDiallist(v) {
    //    console.log("CfgStepList onDiallist", v)
    if (!v) return
    rfmain.current?.classList.add("wv_straight")

    setItems(
      v
        .split(",")
        .map(getModel)
        .map((v, i) => ({
          uid: `CFGSL_${v.rmc}`,
          index: i,
          picture: {
            public_id: `catalogue/${env.catalogYear}/${CLD_FEATURES_MAP.get("dial")}/${v.dial_id}`,
            version: 1,
            width: 800,
            height: 1180,
            policy: "t_v7-main-configurator"
          },
          caption: v.dial,
        }))
    )
  }

  useEffect(() => mvSubscribe(diallist, onDiallist, false), [])

  const { scrollX } = useScroll({
    container: rflist,
    axis: "x",
    offset: ["start end", "end end"],
  })

  const index = useTransform([scrollX, W, N, R], ([scrollX, W, N, R]) => {
    scrollX = Math.abs(scrollX)
    x.set(scrollX)
    return W === 0 ? -1 : ((N * scrollX) / (W * R) + 0.5) >> 0
  })

  function update(v) {
    //    console.log("CfgStepList update", v)
    clearTimeout(rftm.current)
    rftm.current = setTimeout(() => focus.set(v), 140)
  }

  function scrollto(v) {
    //    console.log("CfgStepList scrollto", v)
    if (v === null) return
    const { current: list } = rflist
    if (!list) return
    let scroll = (W.get() * R.get() * v) / N.get()
    scroll = rtl ? -scroll : scroll
    list.scrollLeft = scroll
    rmfocus.set(null)
  }

  function onResize(e) {
    //    console.log("CfgStepList onResize")
    const { current: main } = rfmain
    if (!main) return
    W.set(main.offsetWidth)
    R.set(getComputedStyle(rflist.current).getPropertyValue("--wv-step-offset"))
    N.set(getComputedStyle(rflist.current).getPropertyValue("--wv-step-div"))
  }

  useLayoutEffect(() => {
    window.addEventListener("resize", onResize, false)
    window.addEventListener("orientationchange", onResize, false)
    onResize()
    return () => {
      window.removeEventListener("resize", onResize, false)
      window.removeEventListener("orientationchange", onResize, false)
    }
  }, [])

  useLayoutEffect(() => {
    //    console.log("CfgStepList useLayoutEffect items", items)
    //    console.log(">>>>> items", focus, rmfocus, x, W, N, count)
    if (!items) return
    scrollto(focus.get())
    //    rmfocus.set(-1)
    count.set(items.length)
  }, [items])

  function onFocus(v) {
    //    console.log("CfgStepList onFocus", v, splitted.get())
    if (!splitted.get() || v < 0 || !lastStep()) return
    model.set(splitted.get()[v])
  }

  function onSplitted(v) {
    if (!v || !lastStep()) return
    let focused = v.reduce((a, v, i) => {
      //      console.log("onSplitted focused", a, v, i, model.get())
      if (v === model.get()) a = i
      return a
    }, -1)
    //    if (focused < 0) focused = 0
    //    model.set(v[focused])
    //    console.log("CfgStepList onSplitted", v, "focused", focused)
    focus.set(focused)
  }

  function onDfilter(v) {
    if (!v) return
    setItems(null)
  }

  useLayoutEffect(() => mvSubscribe(index, update), [])
  useLayoutEffect(() => mvSubscribe(rmfocus, scrollto), [])
  //  useLayoutEffect(() => mvSubscribe(readyALL, onReadyALL), [])
  //  useLayoutEffect(() => mvSubscribe(swapping, onSwapping), [])
  useLayoutEffect(() => mvSubscribe(focus, onFocus), [])
  useLayoutEffect(() => mvSubscribe(splitted, onSplitted), [])
  useLayoutEffect(() => mvSubscribe(dfilter, onDfilter, false), [])

  function kill() {
    setItems(null)
  }

  useEffect(() => kill, [])

  //  useEffect(() => mvSubscribe(reset, kill, false), [])

  function onStep(v) {
    //    console.log("CfgStepList onStep", v)
    if (v === 0) kill()
  }

  useEffect(() => mvSubscribe(step, onStep, false), [])

  return (
    <Main ref={rfmain} className="dark-theme">
      <ul ref={rflist}>
        {items?.map(item => (
          <CfgStepListItem ctx={rctx} key={item.uid} {...item} />
        ))}
      </ul>
      <CfgArrows ctx={rctx} />
    </Main>
  )
})
