"use client"

import styled from "@emotion/styled"
import { waTriggerEvent } from "components/analytics/DigitalDataLayer"
import { useConsole } from "contexts/Console"
import { useEnv } from "contexts/Env"
import { useLocale } from "contexts/Locale"
import { useViewport } from "contexts/Viewport"
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import { Helmet } from "react-helmet-async"

import useScript from "hooks/useScript"

const version = "1.11.1"

const Player = styled.video`
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
  top: 50%;
  left: 50%;
`

function CoreVideo({ sources, quality, no_captions = false }, handle) {
  const console = useConsole()
  const locale = useLocale()
  const status = useScript(`https://unpkg.com/cloudinary-video-player@${version}/dist/cld-video-player.min.js`)
  const { orientation } = useViewport()
  const rvideo = useRef()
  const env = useEnv()

  const { cloudinary: { cloudName, secureDistribution } = {} } = env

  const [player, setPlayer] = useState()

  const videos = sources.reduce((acc, v) => {
    acc[v.width / v.height < 1 ? "portrait" : "landscape"] = v
    return acc
  }, {})

  const source = sources.length > 1 ? { portrait: videos.portrait?.public_id, landscape: videos.landscape?.public_id } : sources[0].public_id
  const videoName = sources[0]?.public_id?.replace(/-portrait$/, "")

  const textTracks = no_captions
    ? {}
    : {
        subtitles: {
          label: locale.current.label,
          language: locale.current.codes.www,
          default: true,
          url: `https://${secureDistribution}/raw/upload/${videoName}_${locale.current.codes.www}.vtt`,
        },
        options: {
          fontSize: "2rem",
        },
      }

  useEffect(() => {
    if (status != "ready") return

    const cld = global.cloudinary.Cloudinary.new({
      cloud_name: cloudName,
      secure: !!secureDistribution,
      private_cdn: !!secureDistribution,
      cname: !!secureDistribution && secureDistribution,
      secure_distribution: !!secureDistribution && secureDistribution,
    })

    const player = cld.videoPlayer(rvideo.current, {
      controls: true,
      colors: { base: "rgb(var(--green))", accent: "rgb(var(--green))", text: "#fff" },
      hideContextMenu: true,
      showLogo: false,
      allowUsageReport: false,
      cloudinaryAnalytics: false,
      disablePictureInPicture: true,
      disableRemotePlayback: true,
      playsinline: true, // iOS
      playedEventPercents: [1, 50, 100],
      seekThumbnails: false,
      posterOptions: {
        publicId: "",
        transformation: {
          startOffset: "0",
        },
      },
      preload: "none",
      transformation: { quality: quality ?? "auto:eco" },
      sourceTypes: ["webm/vp9", "mp4/h265", "mp4"],
    })
    player.source(sources.length > 1 ? source[orientation.get()] : source, { textTracks, withCredentials: true })
    setPlayer(player)

    /* Maximize player during playback */
    player.on("play", () => {
      setTimeout(() => {
        player.maximize()
      }, 300)
    })

    /* Exit fullscreen when the end is reached */
    player.on("ended", () => {
      player.exitMaximize()
    })

    player.on("fullscreenchange", () => {
      /* Stop the video if fullscreen is exited */
      if (!player.isMaximized()) {
        player.stop()
      }
    })

    /* WA */
    player.on("percentsplayed", ({ eventData: { percent } }) => {
      waTriggerEvent({
        eventName: `video${percent === 1 ? 0 : percent}`,
        eventType: "Click",
        videoInfo: {
          name: videoName,
        },
      })
    })

    player.on("seek", ({ eventData: { seekStart, seekEnd } }) => {
      const duration = player.duration()

      if (seekStart < duration * 0.5 && seekEnd >= duration * 0.5) {
        waTriggerEvent({
          eventName: "video50",
          eventType: "Click",
          videoInfo: {
            name: videoName,
          },
        })
      }
    })
  }, [status])

  useImperativeHandle(handle, () => player)

  // console.verbose("VideoPlayerCLD(%o)", { textTracks })
  return (
    <>
      <Helmet>
        <link rel='preconnect' href='https://unpkg.com' crossorigin />
        <link rel='dns-prefetch' href='https://unpkg.com' />
        <link href={`https://unpkg.com/cloudinary-video-player@${version}/dist/cld-video-player.min.css`} rel='stylesheet' />
      </Helmet>
      <Player className='cld-video-player' crossorigin='anonymous' ref={rvideo} />
    </>
  )
}

export default forwardRef(CoreVideo)
