import { useEffect, useRef, useLayoutEffect, useState, memo } from "react"
import styled from "@emotion/styled"
import { useFrameAnimation } from "./FrameAnimation"
import { useRollerAnimation } from "./RollerAnimation"
import { cssSwap, rafDelay, fmSwap, fmDisplay, mvSubscribe } from "./utils"
import { useMediaLoader, setPicture, setSource } from "./utils"
import { useEnv } from "contexts/Env"
import Image from "components/media/ImageCLD"
import { VisuallyHidden } from "@react-aria/visually-hidden"

const Main = styled.section`
  position: relative;
  transform-origin: 50% 50%;
//  transform: scale(.9);
//  .wv_appear & {
//    transition: transform 400ms cubic-bezier(0.22, 1, 0.36, 1) 0ms;
//    transform: scale(1);
//  }

//  transform: translateY(-50%);
  
  height: 100%;
  & figure {
    height: 100% !important;
    & img {
      width: auto;
      height: 100% !important;
    }
    &:nth-of-type(1) {
      height: calc(100% * var(--shadow-ratio)) !important;
    }
  }
  
  pointer-events: none;

  &.wv_straight {
    & figure {
      animation-delay: 0ms;
      &.wv_reveal {
        animation-duration: 0ms;
      }
    }
  }

    & figure {
      position: absolute;
      top: 0;
      left: 0;

      animation-fill-mode: both;
      animation-name: wvwrfadeout;
      animation-duration: 0ms;
      animation-timing-function: cubic-bezier(0.61, 1, 0.88, 1);
      animation-delay: 400ms;
      will-change: opacity;
      &.wv_reveal {
        animation-name: wvwrfadein;
        animation-duration: 400ms;
        animation-delay: 0ms;
      }

      &:nth-of-type(1) {
        position: relative;
        opacity: 1;
        animation: none;
      }
      
      @keyframes wvwrfadein {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      @keyframes wvwrfadeout {
        0% {
          opacity: initial;
        }
        100% {
          opacity: 0;
        }
      }

      img {
        display: block;
        -webkit-user-drag: none;
        pointer-events: none;
        position: relative;
//        object-fit: contain;
      }
    }
  }
`

function buildZIndexes(arr) {
  console.log("CfgWatchRoller buildZIndexes", arr)
  let k = 1
  return arr.reduce((a, item) => {
    a[item] = k++
    return a
  }, Object.create(null))
}

function firstPos(arr, item) {
  console.log("CfgWatchRoller firstPos", arr, item)
  const index = arr.indexOf(item)
  if (index < 0) return
  arr.splice(index, 1)
  arr.unshift(item)
}

function animate(list, selected, zIndexes) {
  console.log("CfgWatchRoller animate", list, selected, zIndexes)
  list.forEach((el, i) => {
    el.classList[i === selected ? "add" : "remove"]("wv_reveal")
    rafDelay(() => (el.style.zIndex = zIndexes[i]))
    //    cssApply(el, fade(+(i === selected), zIndexes[i], straight))
  })
}

function useHighlight(items, rfmain, focus) {
  //  console.log(">>> highlight")
  //  console.log(">>>>> useHighlight", items)
  const list = useRef(null)
  const col = useRef(null)
  const swapping = useRef(false)

  const update = focus => {
    console.log("CfgWatchRoller useHighlight update", focus)
    if (swapping.current) return
    if (!list.current) return
    firstPos(list.current, focus)
    const zIndexes = buildZIndexes(list.current.reverse())
    animate(col.current, focus, zIndexes)
    //    console.log(">>> highlight animate")
    rafDelay(() => {
      //    console.log(">>> highlight remove")
      rfmain.current?.classList.remove("wv_straight")
    })
  }

  useLayoutEffect(() => {
    console.log("CfgWatchRoller useHighlight useLayoutEffect", items)
    rfmain.current?.classList.add("wv_straight")
    if (!items) return
    swapping.current = false
    list.current = items.slice(1).map(v => v.index)
    col.current = [...rfmain.current.querySelectorAll("figure")].slice(1)
    update(focus.get())
  }, [items])

  useEffect(() => mvSubscribe(focus, update), [])
}

const CfgWatchRollerItem = memo(({ picture }) => {
  return (
    <figure>
      <Image sources={[picture]} loading='eager' sizes='(min-width: 48rem) 33vw, 50vw' />
    </figure>
  )
})

export const CfgWatchRoller = memo(({ ctx, rctx, diallist }) => {
  const { lastStep, reset, model, beforelaststep, frames, step, amget, pklist, dfilter } = useFrameAnimation(ctx)
  const { focus, readyWR } = useRollerAnimation(rctx)
  const rfmain = useRef()
  const rfwrapper = useRef()
  const [items, setItems] = useState(null)
  const env = useEnv()

  //  let selected = useRef(null).current
  useMediaLoader(readyWR, "CFGWR", rfmain, items, false, 40, 300)

  useHighlight(items, rfmain, focus)

  function build(v) {
    console.log("CfgWatchRoller build", v)
    rfmain.current?.classList.add("wv_straight")
    setItems(
      [
        {
          uid: `CFGWR_shadow`,
          picture: {
            public_id: `catalogue/${env.catalogYear}/upright-c-shadow/${v[0]}`,
            version: 1,
            width: 800,
            height: 1380,
            policy: "t_v7-main-configurator-shadow"
          },
        },
      ].concat(
        v.map((v, i) => ({
          uid: `CFGWR_${v}`,
          index: i,
          picture: {
            public_id: `catalogue/${env.catalogYear}/upright-c/${v}`,
            version: 1,
            width: 800,
            height: 1180,
            policy: "t_v7-main-configurator"
          },
        }))
      ))
  }

  function onDiallist(v) {
    console.log("CfgWatchRoller onDiallist", v)
    if (!v) return
    build(v.split(","))
  }

  function onDfilter(v) {
    if (!v) return
    setItems(null)
  }

  useEffect(() => mvSubscribe(diallist, onDiallist, false), [])
  useLayoutEffect(() => mvSubscribe(dfilter, onDfilter, false), [])

  function kill() {
    setItems(null)
  }
  useEffect(() => kill, [])

  return (
    <Main ref={rfmain} className='wv_straight'>
      {items?.map(item => (
        <CfgWatchRollerItem key={item.uid} {...item} />
      ))}
    </Main>
  )
})
