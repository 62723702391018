import { useEffect, useLayoutEffect, useState } from "react"
import { createPortal } from "react-dom"
import { useFrameAnimation } from "./../FrameAnimation"
import { mvSubscribe } from "./../utils"
import Launcher from "./Launcher"
/*import { useConfigurator } from "./context"
import { useLocale } from "contexts/Locale"*/
import { useDictionary } from "contexts/Dictionary"
import styled from "@emotion/styled"
import getMediaQuery from "css/breakpoints"
import { buttonText, buttonReset, buttonIcon, translucentDark } from "css/buttons"
import { Icon } from "components/icon/Icon"

const Close = styled.button`
  ${buttonIcon}
  ${translucentDark}
  --spacing: calc(var(--outer-margin) - var(--height) / 2);
  ${getMediaQuery("m")} {
    --spacing: 3.125rem;
  }
  inset-block-start: var(--spacing);
  inset-inline-end: var(--spacing);
  position: absolute;
  z-index: 29;
`

export default function FeaturePlayer(props) {
    const { Ctx } = props
    const { feature } = useFrameAnimation(Ctx)
    const [active, setActive] = useState(null)
    const dictionary = useDictionary()

    function kill() {
        setActive(null)
    }

    function onClick(e) {
        feature.set(null)
    }

    function onFeature(v) {
        if (!v) return setTimeout(kill, 500)

        setActive(<Launcher {...props}>
            <Close onClick={onClick} aria-label={dictionary.popinClose()}>
                <Icon type='close' />
            </Close>
        </Launcher>)
    }
    useLayoutEffect(() => mvSubscribe(feature, onFeature, false), [])

    return process.browser && active ? createPortal(active, document.body) : null
}
