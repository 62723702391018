import React, { useLayoutEffect, useRef } from "react"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"
import getMediaQuery from "css/breakpoints"
import { body100, bold } from "css/text"

const FilterItem = styled.li`
  position: relative;
  background-color: transparent;
  border: none;
  padding: 0;

  &:first-of-type label {
    margin-inline-start: 0;
  }
  &:last-of-type label {
    margin-inline-end: 0;
  }
    
  html.prefers-contrast & {
    background: white;
    & * {
      color: black!important;
    }
  }

  & input {
    opacity: 0;
    position: absolute;

    &:checked + label {
      /* color: rgb(var(--rolex-green)); */
      cursor: default;

      &::after {
        opacity: 1;
      }
    }

    &.focus-visible + label {
      outline: 2px solid rgb(var(--focus, 0 255 255) / 1);
      outline-offset: 0px !important;
    }
  }

  & label {
    ${bold}
    white-space: nowrap;
    margin-inline: 20px;
    padding-inline-end: 1rem;
    cursor: pointer;
    transition: color 0.3s;

    ${getMediaQuery("m")} {
      margin-inline: 25px;
    }

    &::after {
      content: "";
      background-color: currentColor;
      border-radius: 50%;
      height: 0.5rem;
      margin-inline-start: 0.5rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 0.5rem;
      opacity: 0;
      transition: opacity 0.3s;
    }

    @media (hover: hover) {
      :hover {
        color: rgb(var(--grey));
      }
    }
  }
`

export default function ConfigFilterItem({ label, index, update, selected }) {
  const rfradio = useRef(null)

  function onChange(e) {
    update(index)
  }

  useLayoutEffect(() => {
    if (index === selected)
      rfradio.current.setAttribute("checked", "")
    else
      rfradio.current.removeAttribute("checked")
  }, [selected])

  return (
    <FilterItem aria-current={selected === index}>
      <input ref={rfradio} name='cfg-filters' id={`cfg-filters-${index}`} type='radio' value={label} onChange={onChange} />
      <label htmlFor={`cfg-filters-${index}`}>{label}</label>
    </FilterItem>
  )
}
