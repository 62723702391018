import hash from "@emotion/hash"
import styled from "@emotion/styled"
import { Icon } from "components/icon/Icon"
import { useConsole } from "contexts/Console"
import { useDictionary } from "contexts/Dictionary"
import { useHeader } from "contexts/Header"
import { useNavigation } from "contexts/Navigation"
import { useWishlist } from "contexts/Wishlist"
import getMediaQuery from "css/breakpoints"
import { body50, bold, legend100, normal, visuallyHidden } from "css/text"
import useSSR from "hooks/useSSR"
import { startTransition, useEffect, useLayoutEffect, useRef, useState } from "react"

import Link from "../link/Link"

const cssOn = `css-${hash("wishlist:toggle:on")}`
const cssOff = `css-${hash("wishlist:toggle:off")}`

const cssWishlistNotificationVisible = `css-${hash("menu:wishlistnotification:visible")}`

const Root = styled.div`
  display: grid;
`

const Button = styled.a`
  all: unset;
  cursor: pointer;
  user-select: none;
  grid-area: 1/1;
  display: grid;
  position: relative;
  align-items: center;
  grid-template-columns: minmax(15px, auto) 1fr;
  padding: var(--touch-padding);
  right: calc(-1 * var(--touch-padding));
  color: rgb(var(--pure-white));

  ${getMediaQuery("xxl", { min: true })} {
    grid-gap: 1ex;
  }

  [dir="rtl"] & {
    left: calc(-1 * var(--touch-padding));
    right: auto;
  }

  > span {
    transition: color 300ms;
    will-change: color;
  }
  > div > svg {
    transition: fill 300ms;
    will-change: fill;
  }

  @media (hover: hover) {
    &:hover {
      > span {
        color: rgb(var(--ocean-green));
      }
      div div:last-child > svg {
        opacity: 1;
      }
    }
  }

  &[disabled] {
    cursor: default;
    pointer-events: none;
  }

  & > span {
    ${legend100}
    ${normal}

    /* ${getMediaQuery("xxl", { max: true })} {
      ${visuallyHidden}
    } */

    & > span {
      font-size: 0%;
      opacity: 0;
      white-space: nowrap;

      ${getMediaQuery("xxl", { min: true })} {
        font-size: min(var(--m, 0) * 100%, 100%);
        opacity: min(var(--m, 0), 1);
      }

      transition: font-size 100ms, opacity 300ms;
    }
  }

  transition: opacity 300ms;
  will-change: opacity;
`

const On = styled(Button)`
  z-index: 1;
  opacity: 1;
  pointer-events: auto;

  .${cssOff} & {
    opacity: 0;
    pointer-events: none;
  }
`

const ButtonContainer = styled.div`
  /* position: relative; */

  .text {
    ${legend100}
    ${normal}
    /* color: rgb(var(--pure-white)); */
    text-decoration: none;

    > span {
      display: none;

      ${getMediaQuery("m")} {
        display: inline;
      }
    }
  }
`

const NotificationAdd = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  left: unset !important;
  right: 0;
  [dir="rtl"] & {
    left: 0 !important;
    right: unset !important;
  }
  width: 100%;
  inset-inline-end: var(--touch-padding);
  margin-inline-end: var(--touch-padding);
  margin-inline-start: var(--touch-padding);
  padding-inline: 1.875rem;
  padding-block: 1.25rem;
  border-radius: 0.5rem;

  ${getMediaQuery("m")} {
    left: unset;
    right: unset;
    inset-inline-start: unset;
    inset-inline-end: var(--grid-col-unit);
    margin-inline-start: unset;
    width: 320px;
    padding-block: 1.25rem;
  }

  background-color: rgb(var(--pure-white));
  box-shadow: 0px 5px 10px rgba(0, 0, 10, 0.2);

  pointer-events: all;
  opacity: 0;
  transform: translateY(40px);
  transition: all 0.3s ease-in-out;

  &.${cssWishlistNotificationVisible} {
    opacity: 1;
    transform: translateY(20px);
  }

  > h3 {
    ${body50}
    color: rgb(var(--rolex-green));
  }

  > p {
    ${legend100}
  }

  > a {
    ${legend100}
    ${bold}
    color: rgb(var(--rolex-green));
    text-decoration: none;

    display: block;
    margin-block-start: 1.25rem;

    ${getMediaQuery("m")} {
      margin-block-start: 0.625rem;
    }
  }
`

const Hearts = styled.div`
  display: flex;
  position: relative;

  div > svg {
    fill: rgb(var(--pure-white));
  }

  div:last-child > svg {
    fill: rgb(var(--ocean-green));
    opacity: 0;
    transition: opacity 300ms;
  }
`

const Heart = styled.div`
  display: flex;
  position: absolute;
  top: -0.625rem;
  z-index: 100;

  > div {
    position: absolute;
    top: -0.625rem;
    right: -0.625rem;
    width: 1.1rem;
    height: 1.1rem;
    border-radius: 50%;
    z-index: 100;

    [dir="rtl"] & {
      right: auto;
      left: -0.625rem;
    }

    display: flex;
    justify-content: center;
    align-items: center;

    ${bold}
    font-size: 10px;
    background-color: rgb(50 144 102);
  }
`

export default function MenuToggle(props) {
  const console = useConsole()
  const navigation = useNavigation()
  const dictionary = useDictionary()
  const ssr = useSSR()

  const [active, setActive] = useState(true)
  const [isNotificationOpen, setIsNotificationOpen] = useState(false) // true when the wishlist notification is in the DOM (opacity: 0)
  const [isNotificationVisible, setIsNotificationVisible] = useState(false) // true when the wishlist notification is visible (opacity: 1)
  const [notificationContent, setNotificationContent] = useState({ action: null })
  const rroot = useRef()
  const header = useHeader()
  const { wishlist, notificationRMC, onNotificationMouseEnter, onNotificationMouseLeave } = useWishlist()

  const href = navigation.localize(`/wishlist`)
  const navigate = e => {
    e.preventDefault()
    navigation.push(href)
  }

  useLayoutEffect(() => header.on(header.events.lock, () => startTransition(() => setActive(false))))
  useLayoutEffect(() => header.on(header.events.unlock, () => startTransition(() => setActive(true))))

  useEffect(() => {
    // This allows the animation to play entirely before the wishlist is removed from DOM
    if (notificationRMC) {
      setNotificationContent(notificationRMC)
      setIsNotificationOpen(true)
      setTimeout(() => {
        setIsNotificationVisible(true)
      }, 0)
    } else {
      setIsNotificationVisible(false)
      setTimeout(() => {
        setIsNotificationOpen(false)
        setNotificationContent(notificationRMC)
      }, 300)
    }
  }, [notificationRMC])

  console.verbose("Wishlist:Toggle(%o)", { active })
  return (
    <Root ref={rroot} className={active ? cssOn : cssOff}>
      <ButtonContainer>
        <On
          as={navigation.slug !== "/wishlist" ? "a" : "div"}
          type={navigation.slug !== "/wishlist" ? "a" : "div"}
          className={navigation.slug === "/wishlist" ? "no-pointer fake-link" : ""}
          href={navigation?.slug !== "/wishlist" ? href : ""}
          onClick={navigate}
          disabled={!active || navigation.slug === "/wishlist"}
          ariaDisabled={navigation.slug === "/wishlist"}
          aria-hidden={!active || navigation.slug === "/wishlist" ? "true" : "false"}
          tabIndex={!active || navigation.slug === "/wishlist" ? -1 : 0}
          style={{ "--m": ssr ? 0 : 1 }}
        >
          <Hearts>
            <Heart>
              {wishlist?.length > 0 && (
                <div>
                  <span>{wishlist?.length}</span>
                </div>
              )}
              <Icon size='18' type={wishlist?.length > 0 ? "heartFull" : "heart"} />
            </Heart>
            <Heart>
              <Icon size='18' type={wishlist?.length > 0 ? "heartFull" : "heart"} />
            </Heart>
          </Hearts>
          <span className='text'>
            <span>{dictionary.myWatches()}</span>
          </span>
        </On>
        <div aria-live="polite">
          {isNotificationOpen ? (
            <NotificationAdd
              className={isNotificationVisible ? cssWishlistNotificationVisible : ""}
              onMouseEnter={onNotificationMouseEnter}
              onMouseLeave={onNotificationMouseLeave}
              // Hide notification when on wishlist page
              style={{ opacity: navigation.slug === "/wishlist" ? 0 : 1 }}
            >
              <h3>{notificationContent.action === "remove" ? dictionary.headerWishlistWatchRemovedTitle() : dictionary.headerWishlistWatchAddedTitle()}</h3>
              <p>{notificationContent.action === "remove" ? dictionary.headerWishlistWatchRemovedText() : dictionary.headerWishlistWatchAddedText()}</p>
              {/* <Link href='/wishlist' className='inline green' aria-disabled={true}>
              {dictionary.headerWishlistCta()}
            </Link> */}
            </NotificationAdd>
          ) : (
            <div style={{ visibility: "hidden" }} />
          )}
        </div>
      </ButtonContainer>
    </Root>
  )
}
