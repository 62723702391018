import ConfiguratorPlayer from "components/configurator-v3/ConfiguratorPlayer"
import Page from "components/page/Page"
import StoryPlayer from "components/stories/player/StoryPlayer"
import { useConsole } from "contexts/Console"
import { Provider } from "contexts/Layout"
import { useLocale } from "contexts/Locale"
import { useNavigation } from "contexts/Navigation"
import { Suspense } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import QuickViewsPlayer from "components/quickviews/player/Player"

export default function Layout({ initialState }) {
  const console = useConsole()
  const locale = useLocale()
  const navigation = useNavigation()

  const { header, footer, footer_p13n, push } = initialState[locale.current.codes.www]

  console.verbose("Layout(%o)", { header, footer, footer_p13n, push })

  return (
    <Provider value={{}}>
      <Switch>
        {navigation.pages.map(({ uid, url, exact, refresh, ...props }) => {
          const path = navigation.route(url)

          //TODO cleanup
          // const pageFooter = Object.assign({}, footer[0] ?? {}, { children: push })
          const pageFooter = Object.assign({}, footer_p13n[0] ?? {}, { children: push })

          console.verbose("Layout:defineRoute(%o)", { url, path, exact })

          return (
            <Route
              key={refresh ? Date.now() : uid}
              path={path}
              exact={exact}
              render={() => {
                return (
                  <Suspense>
                    <StoryPlayer />
                    <QuickViewsPlayer />
                    <ConfiguratorPlayer initialState={initialState} />
                    <Page header={header[0]} footer={pageFooter} uid={uid} {...props} initialState={initialState} />
                  </Suspense>
                )
              }}
            />
          )
        })}
        <Redirect to={navigation.localize("/404")} />
      </Switch>
    </Provider>
  )
}
